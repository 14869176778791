import React, {
  Suspense,
  Fragment,
  lazy
} from 'react';
import {
  Switch,
  Redirect,
  Route
} from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import DocsLayout from 'src/layouts/DocsLayout';
import MainLayout from 'src/layouts/MainLayout';
import HomeView from 'src/views/home/HomeView';
import LoadingScreen from 'src/components/LoadingScreen';
import FallbackScreen from 'src/components/FallbackScreen';
import LoginLoadingScreen from 'src/components/LoginLoadingScreen';
import SigningIn from 'src/components/SigningIn';
import AuthGuard from 'src/components/AuthGuard';
import CustomerListView from'src/views/customer/CustomerListView';
import LoginView from 'src/views/auth/LoginView';

export const renderRoutes = (routes = []) => (
  <Suspense fallback={<FallbackScreen />}>
    <Switch>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;

        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={(props) => (
              <Guard>
                <Layout>
                  {route.routes
                    ? renderRoutes(route.routes)
                    : <Component {...props} />}
                </Layout>
              </Guard>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
);

const routes = [
  {
    exact: true,
    path: '/404',
    component: lazy(() => import('src/views/errors/NotFoundView'))
  },
  {
    exact: true,
    path: '/login',
    component: LoginLoadingScreen
  },
  {
    exact: true,
    path: '/signing-in',
    component: LoadingScreen
  },
  {
    path: '/docs',
    layout: DocsLayout,
    guard: AuthGuard,
    routes: [
      {
        exact: true,
        path: '/docs',
        component: () => <Redirect to="/docs/using-the-api" />
      },
      {
        exact: true,
        path: '/docs/using-the-api',
        component: lazy(() => import('src/views/docs/UsingTheAPIView'))
      },
      {
        exact: true,
        path: '/docs/using-the-api/getting-a-token',
        component: lazy(() => import('src/views/docs/UsingTheAPIView/GettingATokenView'))
      },
      {
        exact: true,
        path: '/docs/using-the-api/making-a-request',
        component: lazy(() => import('src/views/docs/UsingTheAPIView/MakingARequestView'))
      },
      {
        exact: true,
        path: '/docs/using-the-api/parsing-the-response',
        component: lazy(() => import('src/views/docs/UsingTheAPIView/ParsingTheResponseView'))
      },
      {
        exact: true,
        path: '/docs/using-the-api/examples',
        component: lazy(() => import('src/views/docs/UsingTheAPIView/ExamplesView'))
      },
      {
        exact: true,
        path: '/docs/building-a-microservice',
        component: lazy(() => import('src/views/docs/BuildingAMicroserviceView'))
      },
      {
        exact: true,
        path: '/docs/building-a-microservice/overview',
        component: lazy(() => import('src/views/docs/BuildingAMicroserviceView/OverviewView'))
      },
      {
        exact: true,
        path: '/docs/building-a-microservice/web-server',
        component: lazy(() => import('src/views/docs/BuildingAMicroserviceView/WebServerView'))
      },
      {
        exact: true,
        path: '/docs/building-a-microservice/backend',
        component: lazy(() => import('src/views/docs/BuildingAMicroserviceView/BackendView'))
      },
      {
        exact: true,
        path: '/docs/building-a-microservice/authentication-and-authorization',
        component: lazy(() => import('src/views/docs/BuildingAMicroserviceView/AuthenticationView'))
      },
      {
        exact: true,
        path: '/docs/building-a-microservice/deployment',
        component: lazy(() => import('src/views/docs/BuildingAMicroserviceView/DeploymentView'))
      },
      {
        exact: true,
        path: '/docs/backend-logic',
        component: lazy(() => import('src/views/docs/BackendLogicView'))
      },
      {
        exact: true,
        path: '/docs/support',
        component: lazy(() => import('src/views/docs/SupportView'))
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  },
  {
    path: '/',
    layout: DashboardLayout,
    guard: AuthGuard,
    routes: [
      {
        exact: true,
        path: '/',
        component: CustomerListView
      }
    ]
}
];

export default routes;
